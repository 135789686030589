import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/home',
  },
  {
    path: "/home",
    name: "HOME",
    meta: { title: '首页' },
    component: () => import("../views/home.vue")
  },
  {
    path: "/feedback",
    name: "FEEDBACK",
    meta:{ title: '意见反馈' },
    component: () => import("../views/feedback.vue")
  },
  {
    path: "/products",
    name: "PRODUCTS",
    meta:{ title: '产品与服务' },
    component: () => import("../views/products.vue")
  },
  {
    path: "/certificate",
    name: "CERTIFICATE",
    meta:{ title: '资质证书' },
    component: () => import("../views/certificate.vue")
  },
  {
     path: "/news",
     name: "NEWS",
     meta:{ title: '新闻' },
     component: () => import("../views/news.vue")
  },
  {
    path: "/details",
    name: "DETAILS",
    meta:{ title: '新闻详情' },
    component: () => import("../views/details.vue")
  },
  {
    path: "/case",
    name: "CASE",
    meta:{ title: '经典案例' },
    component: () => import("../views/case.vue")
  },
  {
    path: "/project",
    name: "PROJECT",
    meta:{ title: '解决方案' },
    component: () =>  import("../views/project.vue")
  },
  {
    path: "/aboutus",
    name: "ABOUTUS",
    meta:{ title: '关于我们' },
    component: () =>  import("../views/aboutus.vue")
  }
]

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
	scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 1)
    })
	},
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + '—国蔚科技有限公司'
  next()
})

export default router
